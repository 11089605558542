<template>
  <div>
    <v-btn @click="clear">
      clear
    </v-btn>
  </div>
</template>

<script>
import {serverAddress} from "@/js/nft/single";
import {Client, Room} from "colyseus.js";

export default {
  name: "Clear.vue",
  data() {
    return {
      connect: false
    }
  },
  async mounted() {
    this.client = new Client(serverAddress)
    this.room = await this.client.joinOrCreate('nft_room')
    this.connect = true

  },
  methods: {
    clear() {
      this.room.send('clearDraw')
    }
  }
}
</script>

<style scoped>

</style>
